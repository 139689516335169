// @flow
import React from 'react';
import './FlexDrawer.css';

class FlexDrawer extends React.Component {
  render() {
    return (
      <section className={"FlexDrawer " + this.props.className}>
        <header className={"navbar " + this.props.headerClass}>
          <div className="container">
              <h6 style={{ background: "url('" + this.props.headerIcon + "') no-repeat left center" }} className="navbar-text text-uppercase drawer-heading">{this.props.headerTitle}</h6>
          </div>
        </header>
        <div className="container">
          {this.props.children}
        </div>
      </section>
    );
  }
}

class FlexDrawerContainer extends React.Component {
  render() {
    return (
      <div className="FlexDrawerContainer">
        {this.props.children}
      </div>
    );
  }
}

export {FlexDrawer as default, FlexDrawerContainer};