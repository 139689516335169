// @flow
import React from 'react';
import FlexDrawer, { FlexDrawerContainer } from './FlexDrawer';
import Card, { CardsContainer } from './Card';
import AudioPlayer from './AudioPlayer';
//import Sound from "react-sound";
import "./App.css";
import logo from './media/logo.svg';
import graphic1 from './media/alternative.svg';
import graphic2 from './media/alpha.svg';
import graphic3 from './media/extension.svg';
import graphic4 from './media/moon.jpg';
//import graphic5 from './media/dns.svg';
import dashboard from './media/dashboard.png';
import butterfly from './media/butterfly.png';
import rhino from './media/rhino.jpeg';

import facebook_icon from './media/facebook-box.svg';
import twitter_icon from './media/twitter-box.svg';
import instagram_icon from './media/instagram-box.svg';
import mail_icon from './media/mail-box.svg';
import pinterest_icon from './media/pinterest-box.svg';
import youtube_icon from './media/youtube-box.svg';
/*
import icon1 from 'material-design-icons/action/svg/production/ic_lightbulb_outline_24px.svg';
import icon2 from 
'material-design-icons/content/svg/production/ic_add_circle_outline_24px.svg';
*/
import icon3 from "./media/ic_volume_up_24px.svg";
import icon4 from "./media/ic_pause_24px.svg";
import audio_clip from './media/say-aioxm-k.mp3'
import endangered_banner from "./media/endangered.png"
import blue_banner from "./media/theblue.jpeg"

class App extends React.Component {
  render() {
    return <div className="App">
        <FlexDrawerContainer>
          <FlexDrawer className="bg-dark text-white" headerTitle="AIOxm" headerIcon={logo} headerClass="header-main">
            <CardsContainer className="hero">
              <Card className="bg-dark text-white">
                <div className="card-body">
                  <div className="wrap-center">
                    <div className="display-3">
                      <strong>AI</strong>Oxm
                    </div>
                    <h5 className="card-title">
                      (/eiɒksɪm/<AudioPlayer src={audio_clip} defaultIcon={icon3} toggleIcon={icon4} />) is a startup working on problems in the domains of environment and sustainability. Our current focus is the planet's diminishing biodiversity and its ecological impact.
                    </h5>
                  </div>
                </div>
              </Card>
              <Card className="bg-dark text-white">
                <div className="symbol card-body">
                  <div className="display-0 text-center text-success">
                    &#7940;
                  </div>
                </div>
              </Card>
            </CardsContainer>

            <CardsContainer>
            <Card className="bg-intelligence text-dark">
                <div className="card-body">
                  <h5 className="card-title">
                  AI = ALTERNATIVE INTELLIGENCE
                  </h5>
                  <p className="card-text">
                  We recognise the 'Alternative Intelligence' built into the earth's evolutionary processes and the millions of species that inhabit it.
                  </p>
                </div>
                <img src={graphic2} alt="graphic" className="p-0" />
              </Card>
              <Card className="bg-white text-dark">
                <div className="card-body">
                  <h5 className="card-title text-uppercase">O ~ The Earth</h5>
                  <p className="card-text">
                  We believe that the planet's future depends on finding axiom-bending solutions to complex problems.
                  </p>
                </div>
                <img src={graphic1} alt="graphic" className="p-0" />
              </Card>
              <Card className="bg-secondary text-white">
                <div className="card-body">
                  <h5 className="card-title">
                  xm ~ SOLVING FOR IMPACT
                  </h5>
                  <p className="card-text text-white">
                    Our goal is to develop products that spread awareness and generate impact on a mass-scale.
                  </p>
                </div>
                <img src={graphic3} alt="graphic" className="p-0" />
              </Card>

            </CardsContainer>
          </FlexDrawer>
 
          <FlexDrawer className="bg-dark text-white" headerTitle="" headerClass="none">
            <CardsContainer>
            <Card className="bg-endangered-project text-white">
                <div className="card-body">
                  <h5 className="card-title">
                    Species in Danger
                  </h5>
                  <p className="card-text">
                    <a href="https://medium.com/aioxm/endangered/home">
                    <span className="badge badge-success">Read more</span>
                    </a> about the project
                  </p> 
                  <p className="card-text">
                  Buy our t-shirts on <small>(local address required)</small> <a href="https://www.amazon.com/s/ref=bl_sl_s_ap_web_7141123011?ie=UTF8&node=7141123011&field-brandtextbin=AIOxm"><span className="badge badge-warning">Amazon.com</span></a><a href="https://www.amazon.co.uk/s/ref=bl_sl_s_ap_web_83450031?ie=UTF8&node=83450031&field-brandtextbin=AIOxm"> <span className="badge badge-warning">Amazon.co.uk</span></a> <a href="https://www.amazon.de/s/ref=bl_sl_s_ap_web_77028031?ie=UTF8&node=77028031&field-brandtextbin=AIOxm"><span className="badge badge-warning">Amazon.de</span></a>
                  </p> 
                </div>
                  <img src={butterfly} alt="endangered" className="p-0 flex-image" />
              </Card>
              <Card className="bg-white text-dark card-pointer" href="https://endangered.aioxm.com">
                <div className="card-body">
                  <h5 className="card-title">
                    The ENDANGERED dashboard
                  </h5>
                  <p className="card-text">
                    We use data science to map the declines in species.
                  </p>                 
                </div>
                  <img src={dashboard} alt="dashboard" className="p-0 flex-image" />
              </Card>
              <Card className="bg-dark text-white card-pointer" href="https://www.youtube.com/watch?v=e80xCDhwqns">
                <div className="card-body">
                <h5 className="card-title">
                    Watch our film - ENDANGERED
                  </h5>
                  <p className="card-text">Screened at 'Quotes from the Earth' environmental film festival 2018.</p>
                </div>
                <img src={rhino} alt="film" className="p-0 flex-image" />
              </Card>
            </CardsContainer>
          </FlexDrawer>
          
          <FlexDrawer className="bg-dark text-white" headerTitle="" headerClass="none">
            <CardsContainer>
            <Card className="bg-endangered-project-2 text-white card-pointer" href="https://www.youtube.com/channel/UCDWFMjuJOay7kjB2vFt9BGw">
                <div className="card-body">
                  <h5 className="card-title">
                    Subscribe on YOUTUBE
                  </h5>
                  <p className="card-text">
                    Watch all of our films and videos.
                  </p> 
                </div>
                  <img src={endangered_banner} alt="endangered" className="p-0 flex-image" />
              </Card>
              <Card className="bg-the-blue text-white card-pointer" href="http://theblue.in">
                <div className="card-body">
                  <h5 className="card-title">
                    Read THE BLUE
                  </h5>
                  <p className="card-text">
                    A digital magazine about the planet and beyond.
                  </p>                 
                </div>
                  <img src={blue_banner} alt="the blue" className="p-0 flex-image" />
              </Card>
              <Card className="bg-the-blog text-white card-pointer" href="http://blog.aioxm.com">
                <div className="card-body">
                <h5 className="card-title">
                    Follow our BLOG
                  </h5>
                  <p className="card-text">Stay in tune with the latest developments at AIOxm.</p>
                </div>
                <img src={graphic4} alt="blog" className="p-0 flex-image" />
              </Card>
            </CardsContainer>
          </FlexDrawer>

          {/*<FlexDrawer className="bg-light text-dark" headerTitle="Services" headerClass="visible">
            <CardsContainer>
              <Card>
                <div className="card-body">
                  <h5 className="card-title">Ideation.</h5>
                  <p className="card-text">
                    We provide ideation and process engineering consulting
                    to enterprises.
                  </p>
                  <p className="card-text">
                    The areas we may cover are: marketing, product
                    development, brand consulting, enterprise resource
                    planning and business-to-customer solutions.
                  </p>
                </div>
              </Card>
              <Card>
                <img src={graphic5} alt="graphic" className="p-0" />
              </Card>
              <Card>
                <div className="card-body">
                  <h5 className="card-title text-uppercase">
                    Custom Solutions
                  </h5>
                  <p className="card-text">
                    Turnkey technology solutions from requirements
                    definition to execution and deployment.
                  </p>
                  <p className="card-text">
                    We are hands-on with bleeding edge technologies and
                    cloud computing.
                  </p>
                </div>
              </Card>
            </CardsContainer>
          </FlexDrawer>
  */}
          </FlexDrawerContainer>
 
        <div className="container-fluid">
          <CardsContainer className="footer">
            <Card>
              <div className="card-body flex-start">
                <h6 className="card-title">
                  <span className="small">&copy;{(new Date().getFullYear())} </span> AIOxm&reg;
                </h6>
              </div>
            </Card>
            <Card>
              <div className="card-body flex-end">
              <a href="mailto:mail@aioxm.com">
                  <img src={mail_icon} alt="Email us" className="social-icon" />
                </a>
                <a href="https://instagram.com/aioxm">
                  <img src={instagram_icon} alt="on Instagram" className="social-icon" />
                </a>
                <a href="https://twitter.com/aioxm">
                  <img src={twitter_icon} alt="on Twitter" className="social-icon" />
                </a>
                {/*}<a href="https://www.facebook.com/aioxm">
                  <img src={facebook_icon} alt="on Facebook" className="social-icon" />
</a>*/}
                <a href="https://www.pinterest.com/aioxm">
                  <img src={pinterest_icon} alt="on Pinterest" className="social-icon" />
                </a>
                <a href="https://www.youtube.com/channel/UCDWFMjuJOay7kjB2vFt9BGw">
                  <img src={youtube_icon} alt="on Youtube" className="social-icon" />
                </a>
              </div>
            </Card>
          </CardsContainer>
        </div>
      </div>;
  }
}

export default App;
