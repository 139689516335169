// @flow
import React from 'react';
import "./Card.css";

class Card extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }
  handleClick() {
    if (this.props.href !== undefined)
      window.location = this.props.href;
    else {
    }
  }
  render() {
    return(
        <div className={"card border-0 " + this.props.className} onClick={this.handleClick}>
        {this.props.children}
        </div>
          );
        }
      }
      
class CardsContainer extends React.Component {
  render() {
    return (
      <div className={"card-deck " + this.props.className}>
          {this.props.children}
      </div>
    );
  }
}

export {Card as default, CardsContainer};
