// @flow
import React from "react";

class AudioPlayer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {play: false, ended: false, icon: this.props.defaultIcon, label: "Play"};
    this.togglePlay = this.togglePlay.bind(this);
    this.endPlay = this.endPlay.bind(this);
    this.audio = new Audio(this.props.src);
    this.audio.onended = this.endPlay;
  }

  endPlay() {
    this.setState((prevState, props) => ({
      play: false,
      icon: this.props.defaultIcon,
      label: "Play"
    }));
  }

  togglePlay() {
    this.setState((prevState, props) => ({
      play: prevState.play ? false : true,
      icon: prevState.play ? this.props.defaultIcon : this.props.toggleIcon,
      label: prevState.play ? "Pause" : "Play",
    }));
    this.state.play ? this.audio.pause() : this.audio.play();
  }

  render() {
    return <a className="AudioPlayer"  onClick={this.togglePlay}>
            <img src={this.state.icon} alt={this.state.label} className={this.props.className} />
          </a>;
  }
}

export default AudioPlayer;